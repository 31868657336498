<template>
<AppLayout>
    <template v-slot:appContent>
        <div>
            <div class=" d-sm-flex justify-content-between align-items-center pb-1  ">
                <h2 class="">Technician Tracker </h2>

            </div>
            <div class="">
                <div class="">
                    <GMapMap :center="center" :zoom="10" map-type-id="terrain" style="width: 100vw; height: 100%">
                        <GMapCluster :zoomOnClick="true">
                            <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center = m.position" />
                        </GMapCluster>
                    </GMapMap>
                </div>

            </div>
        </div>
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

export default {
    components: {
        AppLayout,

    },
    name: "CreateGeek",
    data() {
        return {
            center: {
                lat: -31.083332,
                lng: 150.916672
            },
            markers: [
        {
          position: {
             lat: -31.083332,
                lng: 150.916672
          },
        },
        
        
      ],
        }

    }
}
</script>

<style >



</style>
